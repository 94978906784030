import { getDefaultState } from 'chimera/all/store/lead'

/**
 * Redirect user to home if the store is untouched.
 *
 * @param context
 * @param context.store
 * @param context.redirect
 * @returns {*}
 */
export default function flowGuard({ store, redirect }) {
  const leadData = store.getters['lead/getData']()
  const storeIsDefault =
    JSON.stringify(leadData) === JSON.stringify(getDefaultState().data)

  if (storeIsDefault) {
    return redirect('/')
  }
}
