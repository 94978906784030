const reasonIsRequired = 'required'
const reasonInvalidLength = 'length'
const reasonInvalidRegex = 'invalid_regex'
const reasonInvalidValue = 'invalid_value'
const reasonUnknownPostal = 'unknown_postal'
const reasonBlacklisted = 'blacklisted'

/**
 * Default error map
 *
 * @type {{id: string, message: string}[]}
 */
const defaultErrorMap = [
  { id: reasonIsRequired, message: 'validation.required' },
  { id: reasonInvalidLength, message: 'validation.invalidLength' },
  { id: reasonInvalidRegex, message: 'validation.invalid' },
  { id: reasonInvalidValue, message: 'validation.invalid' },
  { id: reasonUnknownPostal, message: 'field.postal.validation.unknown' },
  { id: reasonBlacklisted, message: 'validation.blacklisted' },
]

/**
 * Map raw errors to localized ones.
 * Will merge with customErrorMap(), which can be provided by each form part
 *
 * @param {Array} rawErrors
 * @param {Array} customErrorMap
 * @returns {Array}
 */
function mapErrors(rawErrors = [], customErrorMap = []) {
  // Merge default and custom error map, where customErrorMap has priority while overwriting
  const errorMap = defaultErrorMap.map((obj) => {
    const data = customErrorMap.find((item) => item.id === obj.id)
    return { ...obj, ...data }
  })

  const result = []
  for (const rawError of rawErrors) {
    const mappedError = errorMap.find((e) => e.id === rawError)
    result.push(mappedError ? mappedError.message : rawError)
  }

  return result
}

export default {
  defaultErrorMap,
  reasonIsRequired,
  reasonInvalidLength,
  reasonInvalidRegex,
  reasonInvalidValue,
  reasonUnknownPostal,
  reasonBlacklisted,
  mapErrors,
}
