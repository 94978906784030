/**
 * @param {string} id
 * @param {string} label
 * @param {string} value
 * @param {object} options
 */
export function Selectable(id, label, value, options = {}) {
  this.id = id
  this.label = label
  this.value = value
  this.options = { ...options }
}
