/**
 * Mixin to be used for API interaction.
 *
 * @returns {object}
 */
export default {
  /**
   * @returns {object}
   */
  data() {
    return {
      loading: false,
    }
  },

  methods: {
    /**
     * General POST api method
     *
     * @param {string}  endpoint
     * @param {object}  body
     * @param {object}  config
     *
     * @returns {Promise<void>}
     */
    apiPost(endpoint, body, config = {}) {
      const defaultHeaders = {
        'Content-Type': 'application/json',
      }

      config.headers = { ...defaultHeaders, ...config.headers }

      this.loading = true
      const url = endpoint.startsWith('http')
        ? endpoint
        : `${process.env.apiUrl}/${endpoint}`

      return this.$axios.$post(url, body, config).finally(() => {
        this.loading = false
      })
    },

    /**
     * Transform given value when needed to string for API compatibility.
     *
     * @param {any} value
     * @returns {*}
     */
    valueToString(value) {
      let returnValue = value
      // Concatenate when value is an array
      if (Array.isArray(value)) {
        if (typeof value[0] === 'object') {
          value = value.map((val) => val.value)
        }

        returnValue = value.join(', ')
      }

      return returnValue
    },
  },
}
