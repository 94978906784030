<template>
  <div>
    <slot />
  </div>
</template>

<script>
import AbstractPage from 'chimera/all/components/page/AbstractPage'
import FlowGuard from 'chimera/all/middleware/FlowGuard'

export default {
  name: 'AbstractFormPage',

  extends: AbstractPage,

  /**
   * @param {object} context
   * @returns {string}
   */
  layout(context) {
    const layoutPath =
      context.store.getters['configuration/getLandingPage']('layoutPath')
    return layoutPath ? layoutPath + '/form' : 'form'
  },

  middleware: [FlowGuard],

  /**
   * @returns {object}
   */
  data() {
    return {
      checkoutStep: undefined,
    }
  },

  /**
   *
   */
  mounted() {
    // Emit checkoutStepEvent when this page is part of our checkout flow.
    if (this.checkoutStep) {
      this.setCheckoutStep(this.checkoutStep)
    }
  },

  methods: {
    /**
     * @param {number} checkoutStep
     */
    setCheckoutStep(checkoutStep) {
      this.$eventBus.emitCheckoutStepEvent(checkoutStep)
    },
  },
}
</script>
